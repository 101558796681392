import { useCallback, useEffect } from "react";

import { navigate } from "gatsby";

import useAuthorize from "@/hooks/useAuthorize";
import { isBrowser } from "@/utils/env";

const AuthorizeFromMagicLinkPage = () => {
  const urlParams = isBrowser() ? new URLSearchParams(window.location.search.replace("?", "")) : null;
  const token = urlParams?.get("token");

  const onAuthorizeSuccess = useCallback(() => {
    navigate("/catalog");
  }, []);

  const onAuthorizeError = useCallback(() => {
  }, []);

  const { authorizeByToken } = useAuthorize(onAuthorizeSuccess, onAuthorizeError);

  useEffect(() => {
    if (token) {
      authorizeByToken({ token });
    }
  }, [authorizeByToken, token]);

  return null;
};

export default AuthorizeFromMagicLinkPage;
